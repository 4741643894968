<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content todo-sidebar">
        <div class="todo-app-menu">
          <div class="add-task">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block
              @click="
                {
                  // $router.push('/tasks-assigned');
                  currentPage
                  $emit('change-current-page', `${currentPage}`);
                  $emit('change-allow-edit');
                  $emit('update:is-task-handler-sidebar-active', true);
                  $emit('close-left-sidebar');
                }
              "
            >
              Add Task
            </b-button>
          </div>
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="sidebar-menu-list scroll-area"
          >
            <!-- Filters -->
            <b-list-group class="list-group-filters">
              <b-list-group-item
                v-for="filter in taskFilters"
                :key="filter.title + filter.page"
                :active="filter.page == currentPage"
                @click="
                  {
                    $emit('close-left-sidebar');
                    currentPage = filter.page;
                    $emit('change-current-page', filter.page);
                  }
                "
              >
                <!--      :to="filter.route"
                :active="isDynamicRouteActive(filter.route)" -->
                <feather-icon :icon="filter.icon" size="18" class="mr-75" />
                <span class="align-text-bottom line-height-1 cursor-pointer">{{
                  filter.title
                }}</span>
              </b-list-group-item>
            </b-list-group>

            <!-- Tags -->
            <div class="mt-3 px-2 d-flex justify-content-between">
              <h6 class="section-label mb-1">Priority</h6>
            </div>

            <b-list-group class="list-group-labels">
              <b-list-group-item
                v-for="tag in taskTags"
                :key="tag.value"
                :active="tag.value === currentPriority"
                @click="
                  {
                    $emit('close-left-sidebar');
                    currentPriority = tag.value;
                    $emit('change-current-priority', tag.value);
                  }
                "
              >
                <span
                  class="bullet bullet-sm mr-1"
                  :class="`bullet-${tag.color}`"
                />
                <span style="cursor: pointer;">{{ tag.title }}</span>
              </b-list-group-item>
            </b-list-group>
          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { BButton, BListGroup, BListGroupItem } from "bootstrap-vue";
import { isDynamicRouteActive } from "@core/utils/utils";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BListGroup,
    BListGroupItem,
    VuePerfectScrollbar,
  },
  props: {
    taskTags: {
      type: Array,
      required: true,
    },
    allowEdit: {
      type: Boolean,
      required: true,
    },
    currentPage: {
      type: String,
      required: true,
    },
    currentPriority: {
      type: String | null,
      required: true,
    },
  },
  setup(props) {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    };

    const taskFilters = [
      {
        title: "My Task",
        icon: "MailIcon",
        //  route: { name: "apps-todo" }
        page: "tasks",
      },
      {
        title: "Task By Me",
        icon: "MailIcon",
        page: "tasks-assigned",
        // route: { name: "tasks-assigned" },
      },
      {
        title: "Important",
        icon: "StarIcon",
        page: "tasks-important",
        // route: { name: "tasks-important", params: { filter: "important" } },
      },
      {
        title: "Completed",
        icon: "CheckIcon",
        page: "tasks-completed",
        // route: { name: "tasks-completed", params: { filter: "completed" } },
      },
      {
        title: "To Do",
        icon: "MailIcon",
        page: "my-tasks",
        // route: { name: "tasks-deleted", params: { filter: "deleted" } },
      },
      {
        title: "Deleted",
        icon: "TrashIcon",
        page: "tasks-deleted",
        // route: { name: "tasks-deleted", params: { filter: "deleted" } },
      },
    ];

    return {
      perfectScrollbarSettings,
      taskFilters,
      isDynamicRouteActive,
    };
  },
};
</script>

<style></style>
